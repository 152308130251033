<template>
  <div class="container my-5">
    <h2 class="text-center mb-5">{{ $t("sporttab") }}</h2>
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle.accordion-1
            variant="outline-primary"
            class="text-left"
            >{{ $t("sporttab1") }}</b-button
          >
        </b-card-header>
        <b-collapse
          id="accordion-1"
          visible
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body v-if="lang == 'ru'">
            Следующие спортивные соревнования среди детей 7-10 лет будут
            завершены на районном уровне.<br />
            <b> Счастливые старты:</b> количество участников 5 мальчиков, 5
            девочек.<br />
            <b>Шахматы:</b> количество участников 1 мальчик, 1 девочка.<br />
            <b>Шашки:</b> количество участников 1 мальчик, 1 девочка.
          </b-card-body>
          <b-card-body v-if="lang == 'uz_cyrl'">
            7-10 ёшли болалар ўртасида қуйидаги спорт мусобақалари маҳалла
            босқичида якунланади.
            <br />
            <b>Қувноқ стартлар:</b> иштирокчилар сони 5 нафар ўғил бола, 5 нафар
            қиз бола.
            <br />
            <b>Шахмат:</b> иштирокчилар сони 1 нафар ўғил бола, 1 нафар қиз
            бола.

            <br />

            <b> Шашка:</b> иштирокчилар сони 1 нафар ўғил бола, 1 нафар қиз
            бола.
          </b-card-body>
          <b-card-body v-if="lang == 'uz_latn'">
            7-10 yoshli bolalar oʼrtasida quyidagi sport musobaqalari mahalla
            bosqichida yakunlanadi. <br />
            <b> Quvnoq startlar:</b> ishtirokchilar soni 5 nafar oʼgʼil bola, 5
            nafar qiz bola. <br />
            <b> Shaxmat:</b> ishtirokchilar soni 1 nafar oʼgʼil bola, 1 nafar
            qiz bola.
            <br />
            <b>Shashka:</b> ishtirokchilar soni 1 nafar oʼgʼil bola, 1 nafar qiz
            bola.
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle.accordion-2
            variant="outline-primary"
            class="text-left"
            >{{ $t("sporttab2") }}</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <b-card-body v-if="lang == 'ru'">
            Следующие спортивные соревнования среди 11-15-летних будут завершены
            на районном уровне.<br />
            <b> Легкая атлетика:</b> количество участников 3 юноши, 3 девушки (3
            х 60 м, 3 х 100 м (эстафета)).<br />
            <b> Мини-футбол:</b> количество участников – 8 юношей. Шахматы:
            количество участников 1 мальчик, 1 девочка.<br />
            <b>Шашки:</b> количество участников 1 мальчик, 1 девочка.<br />
            <b> Настольный теннис:</b> количество участников 1 мальчик и 1
            девочка.<br />
            <b>Национальные народные игры (перетягивание каната):</b> количество
            участников – 5 юношей.
          </b-card-body>
          <b-card-body v-if="lang == 'uz_cyrl'">
            11-15 ёшлилар ўртасида қуйидаги спорт мусобақалари туман босқичида
            якунланади.
            <br />
            <b> Енгил атлетика: </b> иштирокчилар сони 3 нафар ўғил бола, 3
            нафар қиз бола (3 х 60 метр, 3 х 100 метр (эстафета) масофага
            югуриш).

            <br />
            <b> Мини-футбол:</b> иштирокчилар сони 8 нафар ўғил бола. Шахмат:
            иштирокчилар сони 1 нафар ўғил бола, 1 нафар қиз бола. <br /><b
              >Шашка:</b
            >
            иштирокчилар сони 1 нафар ўғил бола, 1 нафар қиз бола. <br /><b
              >Стол тенниси:</b
            >
            иштирокчилар сони 1 нафар ўғил бола 1 нафар қиз бола. <br /><b
              >Миллий халқ ўйинлари (арқон тортиш):</b
            >
            иштирокчилар сони 5 нафар ўғил болалар.
          </b-card-body>
          <b-card-body v-if="lang == 'uz_latn'">
            11-15yoshlilar oʼrtasida quyidagi sport musobaqalari tuman
            bosqichida yakunlanadi. <br />
            <b> Yengil atletika:</b> ishtirokchilar soni 3 nafar oʼgʼil bola, 3
            nafar qiz bola (3 x 60 metr, 3 x 100 metr (estafeta) masofaga
            yugurish).
            <br />
            <b> Mini-futbol:</b> ishtirokchilar soni 8 nafar oʼgʼil bola.
            Shaxmat: ishtirokchilar soni 1 nafar oʼgʼil bola, 1 nafar qiz bola.
            <br />
            <b> Shashka:</b> ishtirokchilar soni 1 nafar oʼgʼil bola, 1 nafar
            qiz bola.
            <br />
            <b> Stol tennisi:</b> ishtirokchilar soni 1 nafar oʼgʼil bola 1
            nafar qiz bola. <br />
            <b> Milliy xalq oʼyinlari (arqon tortish):</b> ishtirokchilar soni 5
            nafar oʼgʼil bolalar.
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle.accordion-3
            variant="outline-primary"
            class="text-left"
            >{{ $t("sporttab3") }}</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
          <b-card-body v-if="lang == 'ru'">
            В финале страны примут участие следующие спортивные соревнования
            между юношей и девушек 16-21 года.<br />
            Легкая атлетика: количество участников 3 юноши, 3 девушки (3 х 100
            м, 3 х 400 м (эстафета)).<br />
            <b>Разминка:</b> количество участников – 1 подросток.<br />
            <b>Шахматы:</b> количество участников 1 мальчик, 1 девочка.<br />
            <b> Шашки:</b> количество участников 1 подросток, 1 девушка.<br />
            <b>Настольный теннис:</b> количество участников 1 подросток, 1
            девушка.<br />
            <b>Волейбол:</b> количество участников 9 юношей, 9 девушек.<br />
            <b>Стритбол:</b> количество участников 5 юношей, 5 девушек.<br />
            <b>Народная борьба:</b> участники 3 подростка, 3 девушки
          </b-card-body>
          <b-card-body v-if="lang == 'uz_cyrl'">
            16-21 ёшли ўсмир ва қизлар ўртасида қуйидаги спорт мусобақалари
            республика финал босқичида иштирок этади.

            <br /><b>Енгил атлетика:</b> иштирокчилар сони 3 нафар ўғил бола, 3
            нафар қиз бола (3 х 100 метр, 3 х 400 метр (эстафета) масофага
            югуриш). <br /><b>Workout:</b> иштирокчилар сони 1 нафар ўсмир.
            <br /><b>Шахмат:</b> иштирокчилар сони 1 нафар ўсмир, 1 нафар
            қизлар. <br /><b>Шашка:</b> иштирокчилар сони 1 нафар ўсмир, 1 нафар
            қизлар. <br /><b>Стол тенниси:</b> иштирокчилар сони 1 нафар ўсмир,
            1 нафар қизлар. <br /><b>Волейбол:</b> иштирокчилар сони 9 нафар
            ўсмир, 9 нафар қизлар. <br /><b>Стритбол:</b> иштирокчилар сони 5
            нафар ўсмирлар, 5 нафар қизлар. <br /><b>Миллий кураш:</b>
            иштирокчилар 3 нафар ўсмирлар, 3 нафар қизлар.
          </b-card-body>
          <b-card-body v-if="lang == 'uz_latn'">
            16-21 yoshli oʼsmir va qizlar oʼrtasida quyidagi sport musobaqalari
            respublika final bosqichida ishtirok etadi. <br />
            <b> Yengil atletika:</b> ishtirokchilar soni 3 nafar oʼgʼil bola, 3
            nafar qiz bola (3 x 100 metr, 3 x 400 metr (estafeta) masofaga
            yugurish).
            <br />
            <b>Workout:</b> ishtirokchilar soni 1 nafar oʼsmir. <br />
            <b> Shaxmat:</b> ishtirokchilar soni 1 nafar oʼsmir, 1 nafar qizlar.
            <br />
            <b>Shashka:</b> ishtirokchilar soni 1 nafar oʼsmir, 1 nafar qizlar.
            <br />
            <b> Stol tennisi:</b> ishtirokchilar soni 1 nafar oʼsmir, 1 nafar
            qizlar.
            <br />
            <b>Voleybol:</b> ishtirokchilar soni 9 nafar oʼsmir, 9 nafar qizlar.
            <br />
            <b>Stritbol:</b> ishtirokchilar soni 5 nafar oʼsmirlar, 5 nafar
            qizlar.
            <br />
            <b> Milliy kurash:</b> ishtirokchilar 3 nafar oʼsmirlar, 3 nafar
            qizlar
          </b-card-body>
        </b-collapse>
      </b-card>

      <!-- <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-4 variant="outline-primary" class="text-left">4. {{ $t('getlicense') }} </b-button>
      </b-card-header>
      <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
        <b-card-body v-if="lang == 'ru'">
          Процедура получения заключения о возможности быть усыновителем не может занимать более 10 рабочих дней после предоставления в орган опеки и попечительства полного пакета документов.



<br><br>Если вы ранее получали заключение о возможности быть опекуном или усыновителем и оно еще действительно, получать новое заключение вам не требуется.
        </b-card-body>
        <b-card-body v-if="lang == 'uz_cyrl'">
         Фарзандликка олиш имконияти тўғрисида хулоса олиш тартиби васийлик ва ҳомийлик органига тўлиқ ҳужжатлар тўплами тақдим етилгандан сўнг 10 иш кунидан ортиқ вақт талаб қилинмайди.


<br><br>Aгар илгари васий ёки фарзандликка олиш имконияти тўғрисида хулоса олган бўлсангиз ва у ҳали ҳам ҳақиқатан ҳам бўлса, сиз янги хулоса олишингиз шарт емас.


        </b-card-body>
        <b-card-body v-if="lang == 'uz_latn'">
         Farzandlikka olish imkoniyati to'g'risida xulosa olish tartibi vasiylik va homiylik organiga to'liq hujjatlar to'plami taqdim etilgandan so'ng 10 ish kunidan ortiq vaqt talab qilinmaydi.


<br><br>Agar ilgari vasiy yoki farzandlikka olish imkoniyati to'g'risida xulosa olgan bo'lsangiz va u hali ham haqiqatan ham bo'lsa, siz yangi xulosa olishingiz shart emas.


        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-5 variant="outline-primary" class="text-left">5. {{ $t('howtoregistered') }} </b-button>
      </b-card-header>
      <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
        <b-card-body v-if="lang == 'ru'">
          <b>Если вы постоянно проживаете в Узбекистан:</b>
          <br><br>



Встать на учет в качестве усыновителя можно как в том же органе опеки и попечительства, в котором вы получали заключение о возможности быть усыновителем, так и в любом другом органе опеки и попечительства.

 <br><br>

При постановке на учет требуется предъявить документ, удостоверяющий личность, ПИНФЛ и заключение о возможности быть усыновителем. Также необходимо заполнить заявление.

 <br><br>

<b>Если вы постоянно проживаете на территории иностранного государства:</b>


 <br><br>
На территории иностранного государства вам необходимо собрать следующие документы:
 <br><br>
 1. Обязательство поставить в установленном порядке на учет в соответствующем консульском учреждении Республики Узбекистан усыновленного вами ребенка;
 <br>2. Обязательство предоставлять возможность для обследования условий жизни и воспитания усыновленного ребенка;
 <br>3. Заключение компетентного органа об условиях вашей жизни и возможности быть усыновителем. К заключению прилагаются фотоматериалы о семье, а также копия лицензии органа;
 <br>4. Обязательство компетентного органа осуществлять контроль за условиями вашей жизни и воспитания усыновленного ребенка и представлять соответствующие отчеты, а также копия лицензии органа;
 <br>5. Обязательство компетентного органа проконтролировать постановку на учет в консульском учреждении Республики Узбекистан усыновленного ребенка, а также копия лицензии органа;
 <br>6. Если вы не состоите в браке — документ компетентного органа;
 <br>7. Cвидетельство о прохождении подготовки кандидатов в усыновители (подготовку можно пройти как в Узбекистан, так и в вашем государстве. Если подготовка проходит за пределами Республики Узбекистан, вам также необходимо предоставить документ, подтверждающий право организации осуществлять подготовку и копию программы. Если программа подготовки приемных родителей в иностранном государстве существенно отличается от узбекской, свидетельство о прохождении подготовки могут признать недействительным).

 <br><br>
Все документы должны быть легализованы. После легализации они должны быть переведены на узбекский язык, перевод должен быть заверен нотариально. С документами вам нужно обратиться в орган опеки и попечительства по месту жительства ребенка.
        </b-card-body>

        <b-card-body v-if="lang == 'uz_cyrl'">
          <b>Aгар сиз доимий равишда Ўзбекистонда яшасангиз:</b>
          <br><br>



Фарзандликка олувчи сифатида рўйхатдан ўтиш учун сиз васийлик ва ҳомийлик органида ҳам бўлиши мумкин, унда сиз фарзандликка олиш имконияти ва бошқа васийлик ва ҳомийлик органида хулоса оласиз.
 <br><br>

Рўйхатга олиш жараёнида шахсни тасдиқловчи ҳужжат, ПИНФЛ ва фарзандликка олиш имконияти тўғрисида хулоса чиқариш талаб қилинади. Шунингдек, аризани тўлдириш керак.
 <br><br>

<b>Aгар сиз доимий равишда хорижий давлат ҳудудида яшасангиз:</b>


 <br><br>
Хорижий давлат ҳудудида сиз қуйидаги ҳужжатларни тўплашингиз керак:
 <br><br>
 1. Сиз қабул қилган болани Ўзбекистон Республикасининг тегишли консуллик муассасасида белгиланган тартибда ҳисобга қўйиш мажбурияти;
 <br>2. Фарзандликка олинган боланинг турмуш шароитини текшириш ва уни тарбиялаш учун имконият бериш мажбурияти;
 <br>3. Сизнинг ҳаётингизнинг шартлари ва фарзандликка олиш имконияти тўғрисида ваколатли органнинг хулосаси. Хулоса учун оила ҳақидаги фотосуратлар, шунингдек, орган лицензиясининг нусхаси илова қилинади;
 <br>4. Ваколатли органнинг фарзандликка олинган боланинг ҳаёти ва тарбиясини назорат қилиш ва тегишли ҳисоботларни тақдим етиш мажбуриятини, шунингдек орган лицензиясининг нусхасини тақдим етади;
 <br>5. Ваколатли органнинг фарзандликка олинган боланинг Ўзбекистон Республикасининг консуллик муассасасида ҳисобга қўйилишини, шунингдек орган лицензиясининг нусхасини назорат қилиш мажбурияти;
 <br>6. Агар турмуш қурмаган бўлсангиз — ваколатли органнинг ҳужжати;
 <br>7. Фарзандликка олувчиларга номзодларни тайёрлаш тўғрисидаги гувоҳнома (тайёргарлик Ўзбекистон ва сизнинг давлатингизда ҳам ўтказилиши мумкин. Aгар тайёргарлик Ўзбекистон Республикасидан ташқарида ўтказилса, сиз ташкилотнинг дастурни тайёрлаш ва нусхасини амалга ошириш ҳуқуқини тасдиқловчи ҳужжатни тақдим етишингиз керак. Aгар чет елда ота-оналарни тайёрлаш дастури ўзбек тилидан сезиларли даражада фарқ қилса, тайёргарликдан ўтганлик тўғрисидаги гувоҳнома ҳақиқий емас деб топилиши мумкин).
 <br><br>
Барча ҳужжатлар қонунийлаштирилиши керак. Легаллаштирилгандан сўнг улар ўзбек тилига таржима қилиниши, таржима нотариал тасдиқланган бўлиши керак. Ҳужжатлар билан сиз боланинг яшаш жойидаги васийлик ва ҳомийлик органига мурожаат қилишингиз керак.
        </b-card-body>

        <b-card-body v-if="lang == 'uz_latn'">
          <b>Agar siz doimiy ravishda O'zbekistonda yashasangiz:</b>
          <br><br>



Farzandlikka oluvchi sifatida ro'yxatdan o'tish uchun siz vasiylik va homiylik organida ham bo'lishi mumkin, unda siz farzandlikka olish imkoniyati va boshqa vasiylik va homiylik organida xulosa olasiz.
 <br><br>

Ro'yxatga olish jarayonida shaxsni tasdiqlovchi hujjat, PINFL va farzandlikka olish imkoniyati to'g'risida xulosa chiqarish talab qilinadi. Shuningdek, arizani to'ldirish kerak.
 <br><br>

<b>Agar siz doimiy ravishda xorijiy davlat hududida yashasangiz:</b>


 <br><br>
Xorijiy davlat hududida siz quyidagi hujjatlarni to'plashingiz kerak:
 <br><br>
 1. Siz qabul qilgan bolani O'zbekiston Respublikasining tegishli konsullik muassasasida belgilangan tartibda hisobga qo'yish majburiyati;
 <br>2. Farzandlikka olingan bolaning turmush sharoitini tekshirish va uni tarbiyalash uchun imkoniyat berish majburiyati;
 <br>3. Sizning hayotingizning shartlari va farzandlikka olish imkoniyati to'g'risida vakolatli organning xulosasi. Xulosa uchun oila haqidagi fotosuratlar, shuningdek, organ litsenziyasining nusxasi ilova qilinadi;
 <br>4. Vakolatli organning farzandlikka olingan bolaning hayoti va tarbiyasini nazorat qilish va tegishli hisobotlarni taqdim etish majburiyatini, shuningdek organ litsenziyasining nusxasini taqdim etadi;
 <br>5. Vakolatli organning farzandlikka olingan bolaning O'zbekiston Respublikasining konsullik muassasasida hisobga qo'yilishini, shuningdek organ litsenziyasining nusxasini nazorat qilish majburiyati;
 <br>6. Agar turmush qurmagan bo'lsangiz — vakolatli organning hujjati;
 <br>7. Farzandlikka oluvchilarga nomzodlarni tayyorlash to'g'risidagi guvohnoma (tayyorgarlik O'zbekiston va sizning davlatingizda ham o'tkazilishi mumkin. Agar tayyorgarlik O'zbekiston Respublikasidan tashqarida o'tkazilsa, siz tashkilotning dasturni tayyorlash va nusxasini amalga oshirish huquqini tasdiqlovchi hujjatni taqdim etishingiz kerak. Agar chet elda ota-onalarni tayyorlash dasturi o'zbek tilidan sezilarli darajada farq qilsa, tayyorgarlikdan o'tganlik to'g'risidagi guvohnoma haqiqiy emas deb topilishi mumkin).

 <br><br>
Barcha hujjatlar qonuniylashtirilishi kerak. Legallashtirilgandan so'ng ular o'zbek tiliga tarjima qilinishi, tarjima notarial tasdiqlangan bo'lishi kerak. Hujjatlar bilan siz bolaning yashash joyidagi vasiylik va homiylik organiga murojaat qilishingiz kerak.
        </b-card-body>
      </b-collapse>
    </b-card> -->

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle.accordion-6
            variant="outline-primary"
            class="text-left"
            >{{ $t("sporttab4") }}</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
          <b-card-body v-if="lang == 'ru'">
            В республиканском этапе проходят следующие спортивные соревнования
            между мужчинами и женщинами 22-26 лет.<br />
            <b> Легкая атлетика:</b> количество участников 3 мужчины, 3 женщины
            (3 х 100 м, 3 х 400 м (эстафета)).<br />
            <b> Стритбол:</b> количество участников 5 мужчин, 5 женщин.<br />
            <b>Настольный теннис:</b> количество участников 1 мужчина, 1
            девушка.<br />
            <b>Волейбол:</b> количество участников 9 мужчин, 9 женщин.<br />
            <b>Разминка:</b> количество участников 1 подросток.<br />
            <b>Шахматы:</b> количество участников 1 мужчина, 1 женщина.<br />
            <b>Шашки:</b> количество участников 1 мужчина, 1 женщина.<br />
            <b>Национальная борьба:</b> количество участников 3 мужчины, 3
            женщины
          </b-card-body>
          <b-card-body v-if="lang == 'uz_cyrl'">
            22-26 ёшли эркак ва аёллар ўртасида қуйидаги спорт мусобақалари
            республика босқичида иштирок этади. <br />
            <b>Енгил атлетика:</b> иштирокчилар сони 3 нафар эркака, 3 нафар
            аёллар (3 х 100 метр, 3 х 400 метр (эстафета) масофага югуриш).
            <br />
            <b>Стритбол:</b> иштирокчилар сони 5 нафар эркаклар, 5 нафар аёллар.
            <br />
            <b>Стол тенниси:</b> иштирокчилар сони 1 нафар эркак,1 нафараёллар.
            <br />
            <b>Волейбол:</b> иштирокчилар сони 9 нафар эркаклар, 9 нафар аёллар.
            <br />
            <b>Workout:</b> иштирокчилар сони 1 нафар ўсмирлар. <br />
            <b>Шахмат:</b> иштирокчилар сони 1 нафар эркак, 1 нафар аёллар.
            <br />
            <b>Шашка:</b> иштирокчилар сони 1 нафар эркак, 1 нафар аёллар.
            <br />
            <b>Миллий кураш:</b> иштирокчилар сони 3 нафар эркаклар, 3 нафар
            аёллар.
          </b-card-body>
          <b-card-body v-if="lang == 'uz_latn'">
            22-26 yoshli erkak va ayollar oʼrtasida quyidagi sport musobaqalari
            respublika bosqichida ishtirok etadi. <br />
            <b>Yengil atletika:</b> ishtirokchilar soni 3 nafar erkaka, 3 nafar
            ayollar (3 x 100 metr, 3 x 400 metr (estafeta) masofaga yugurish).
            <br />
            <b>Stritbol:</b> ishtirokchilar soni 5 nafar erkaklar, 5 nafar
            ayollar.
            <br />
            <b> Stol tennisi:</b> ishtirokchilar soni 1 nafar erkak,1
            nafarayollar.
            <br />
            <b>Voleybol:</b> ishtirokchilar soni 9 nafar erkaklar, 9 nafar
            ayollar.
            <br />
            <b>Workout:</b> ishtirokchilar soni 1 nafar oʼsmirlar. <br />
            <b>Shaxmat:</b> ishtirokchilar soni 1 nafar erkak, 1 nafar ayollar.
            <br />
            <b>Shashka:</b> ishtirokchilar soni 1 nafar erkak, 1 nafar ayollar.
            <br />
            <b>Milliy kurash:</b> ishtirokchilar soni 3 nafar erkaklar, 3 nafar
            ayollar
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle.accordion-7
            variant="outline-primary"
            class="text-left"
          >
            {{ $t("sporttab5") }}</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
          <!-- <statistic-count class="mt-4"></statistic-count> -->
          <b-card-body v-if="lang == 'ru'">
            В республиканском этапе проходят следующие спортивные соревнования
            между мужчинами и женщинами 27-30 лет.<br />
            <b>Легкая атлетика:</b> количество участников 3 мужчины, 3 женщины
            (3 х 100 м, 3 х 400 м (эстафета)).<br />
            <b>Стритбол:</b> количество участников 5 мужчин, 5 женщин.<br />
            <b>Настольный теннис:</b> количество участников 1 мужчина, 1
            девушка.<br />
            <b>Разминка:</b> количество участников 1 подросток.<br />
            <b> Шахматы:</b> количество участников 1 мужчина, 1 женщина.<br />
            <b> Шашки:</b> количество участников 1 мужчина, 1 женщина
          </b-card-body>
          <b-card-body v-if="lang == 'uz_cyrl'">
            27-30 ёшли эркак ва аёллар ўртасида қуйидаги спорт мусобақалари
            республика босқичида иштирок этади. <br />
            <b> Енгил атлетика:</b> иштирокчилар сони 3 нафар эркака, 3 нафар
            аёллар (3 х 100 метр, 3 х 400 метр (эстафета) масофага югуриш).
            <br />
            <b> Стритбол:</b> иштирокчилар сони 5 нафар эркаклар, 5 нафар
            аёллар. <br />
            <b>Стол тенниси:</b> иштирокчилар сони 1 нафар эркак,1 нафараёллар.
            <br />
            <b> Workout:</b> иштирокчилар сони 1 нафар ўсмирлар. <br />
            <b> Шахмат:</b> иштирокчилар сони 1 нафар эркака, 1 нафар аёллар.
            <br />
            <b>Шашка:</b> иштирокчилар сони 1 нафар эркак, 1 нафар аёллар
          </b-card-body>
          <b-card-body v-if="lang == 'uz_latn'">
            27-30 yoshli erkak va ayollar oʼrtasida quyidagi sport musobaqalari
            respublika bosqichida ishtirok etadi. <br />
            <b> Yengil atletika:</b> ishtirokchilar soni 3 nafar erkaka, 3 nafar
            ayollar (3 x 100 metr, 3 x 400 metr (estafeta) masofaga yugurish).
            <br />
            <b> Stritbol:</b> ishtirokchilar soni 5 nafar erkaklar, 5 nafar
            ayollar.
            <br />
            <b>Stol tennisi:</b> ishtirokchilar soni 1 nafar erkak,1
            nafarayollar.
            <br />
            <b>Workout:</b> ishtirokchilar soni 1 nafar oʼsmirlar. <br />
            <b>Shaxmat:</b> ishtirokchilar soni 1 nafar erkaka, 1 nafar ayollar.
            <br />
            <b> Shashka:</b> ishtirokchilar soni 1 nafar erkak, 1 nafar ayollar
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import statisticCount from "./statisticCount.vue";
export default {
  components: { statisticCount },
  data() {
    return {
      lang: localStorage.getItem("locale"),
    };
  },
};
</script>

<style></style>
